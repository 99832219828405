<template>
    <div class="container">
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true}"></v-header>
        </div>
        <div class="questionList">
            <div class="question">
                <p>1、什么是拉黑？</p>
                <div class="arrowDiv" @click="arrow(0)">
                    <span class="arrow"></span>
                </div>
                <div class="answer">
                    <p>拉黑是用来拒绝喷子在您的帖子中发表评论；</p>
                    <p>有了拉黑功能，您的帖子您做主，更能聚合志同道合的彩友一起研究。</p>
                </div>
            </div>
            <div class="question">
                <p>2、怎么拉黑喷子？</p>
                <div class="arrowDiv" @click="arrow(1)">
                    <span class="arrow"></span>
                </div>
                <div class="answer">
                    <p>(1) 进入彩友圈的帖子中，点击帖子回复信息的右下角【设置】/【...】按钮，选择【拉黑TA】</p>
                    <p>(2) 或者通过点击彩友头像，进入彩友的【个人信息】界面，点击右上角的【设置】-【拉黑屏蔽】。</p>
                </div>
            </div>
            <div class="question">
                <p>3、评论不成功，如何申请解除屏蔽？</p>
                <div class="arrowDiv" @click="arrow(2)">
                    <span class="arrow"></span>
                </div>
                <div class="answer">
                    <p>(1) 被楼主屏蔽后，评论时会弹出被屏蔽的提示，点击【请求解除屏蔽】-选择内容发给楼主；</p>
                    <p>(2) 点击楼主头像，进入楼主的【个人信息】界面，点击右上角【设置】-【请求解除屏蔽】-选择内容发给楼主。</p>
                </div>
            </div>
            <div class="question">
                <p>4、如何解除彩友的屏蔽？</p>
                <div class="arrowDiv"  @click="arrow(3)">
                    <span class="arrow"></span>
                </div>
                <div class="answer">
                    <p>(1) 彩友申请解除屏蔽后，会收到一条【请求解除屏蔽】的系统信息，点击信息-【解除屏蔽】；</p>
                    <p>(2) 或者主界面左下角【我】-【拉黑列表】-点击要解除屏蔽彩友的右侧【解除屏蔽】按钮</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'

export default {
    data(){
        return{
            title:this.$route.meta.title,
        }
    },
    components:{
        vHeader
    },
    mounted:function(){
    //判断网页是什么打开
        let isWeiXin = isWeixin()
        if(isWeiXin){
        this.showHeader = false
        }
        if(this.showHeader==false){
        document.title = ''
        }
    },
    methods:{
        arrow(val){
            this.direction = !this.direction
            let answers = document.getElementsByClassName('answer')
            let arrows = document.getElementsByClassName('arrow')
            for(let i=0;i<answers.length;i++){
                answers[i].style.height = 0;
            }
            answers[val].style.height = 'auto'
            
            for(let i=0;i<arrows.length;i++){
                arrows[i].className = 'arrow';
            }
            arrows[val].className = 'arrow change-direction'
            if(!this.direction){
                answers[val].style.height = 0
                arrows[val].className = 'arrow';
            }
        }
    }
}
</script>
<style lang="less" scoped>
    .container{
        width: 100%;
        height: auto;
        position: absolute;
        background-color: #eee;
        top: 0;
        .questionList{
            width: 100%;
            min-height: 100px;
            border-top: 1px solid #ccc;
            background-color: #fff;
            .question{
                position: relative;
                border-bottom: 1px solid #ccc;
                p{
                    font-weight: normal;
                    height: 42px;
                    line-height: 32px;
                    padding: 10px 0 0 6px;
                    margin: 0 10px;
                    font-size: 18px;
                } 
                .arrowDiv{
                    width: 30px;
                    height: 30px;
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    .arrow{
                        position: absolute;
                        right:8px;
                        top: 10px;
                        width: 8px;
                        height: 8px;
                        border-top: 2px solid #555;
                        border-right: 2px solid #555;
                        transform: rotate(135deg);
                        transform-origin:center;
                        transition: all 0.2s linear;
                    }
                    .change-direction{
                        transform: rotate(-45deg);
                    }
                }
                .answer{
                    background-color: #eee;
                    padding-left: 4px ;
                    color: #555;
                    overflow: hidden;
                    height: 0;
                    p{
                        padding: 0;
                        margin: 0;
                        font-size: 14px;
                        width: 100%;
                        height: auto;              
                    }
                }
            }
        }
    }
</style>